<template>
 <div class="">
    <v-card class="mt-4 pl-3 pr-3" min-width="100%">
      <v-row>
          <v-col cols="6" sm="3" class="datas-input">
                  
                  <v-menu
                    v-model="menuDateInicial"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                    min-width="290px">

                      <template v-slot:activator="{ on }">
                        <v-text-field
                          v-model="dtInicialString"
                          v-mask="['##/##/####']"
                          :label="$gettext('Data Inicial')"
                          @input="testeDate('inicial', dtInicialString)"
                          dense
                          v-on="on">
                          <template v-slot:append>
                            <v-icon v-on="on">
                              event
                            </v-icon>
                          </template>
                        </v-text-field>
                      </template>

                    <v-date-picker
                      v-model="dtInicial"
                      no-title
                      scrollable
                      locale="pt-br"
                      @input="closeModalDatePicker('inicial', dtInicial)"
                      :color="variables.colorPrimary">
                        <v-spacer></v-spacer>
                        <v-btn text color="primary" @click="clearDateFilter('inicial', dtInicial)">Limpar</v-btn>
                        <v-btn text color="primary" @click="closeModalDatePicker('inicial', dtInicial)">OK</v-btn>
                    </v-date-picker>
                  </v-menu>

          </v-col>

        <v-col cols="6" sm="3" class="datas-input">
          <!-- DT FINAL -->
              <v-menu
                v-model="menuDateFinal"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                min-width="290px">

              <template v-slot:activator="{ on }">
                <v-text-field
                  v-model="dtFinalString"
                  :label="$gettext('Data Final')"
                  v-mask="['##/##/####']"
                  @input="testeDate('final', dtFinalString)"
                  v-on="on"
                  dense>
                  <template v-slot:append>
                    <v-icon v-on="on">
                      event
                    </v-icon>
                  </template>
                </v-text-field>
              </template>

            <v-date-picker
              v-model="dtFinal"
              no-title
              scrollable
              locale="pt-br"
              @input="closeModalDatePicker('final', dtFinal)"
              :color="variables.colorPrimary">
                <v-spacer></v-spacer>
                <v-btn text color="primary" @click="clearDateFilter('final', dtFinal)">Limpar</v-btn>
                <v-btn text color="primary"  @click="closeModalDatePicker('final', dtFinal)">OK</v-btn>
            </v-date-picker>
          </v-menu>
        </v-col>

          <v-col cols="6" sm="3">

            <!-- Promotor -->
            <v-row>
              <v-col cols="12" class="pb-0 mt-4">
                  <autoComplete
                    :entity.sync="filtro.pStart" :typesEntities="['3']"
                    :type="'p'"
                    :return-object="true" />
              </v-col>

              <!-- <v-col cols="12" class="pt-0 pb-0 mt-2">
                <autoComplete
                  :entity.sync="filtro.pEnd" :typesEntities="['3']"
                  label="Promotor final"
                  :type="'pEnd'"
                  :return-object="true" />
              </v-col> -->
            </v-row>
          </v-col>

          <!-- Revendedor -->
          <v-col cols="6" sm="3">
              <v-row>
                <v-col cols="12" class="pb-0 mt-4">
                  <autoComplete
                  :entity.sync="filtro.vStart" :typesEntities="['4']"
                  :type="'v'"
                  :return-object="true" />
                </v-col>
                <v-col cols="12" class="pt-0 pb-0 mt-2">
                <!-- <autoComplete
                    :entity.sync="filtro.vEnd" :typesEntities="defaultEntities"
                    label="Vendedor final"
                    :type="'vEnd'"
                    :return-object="true"
                  /> -->
                </v-col>
              </v-row>
          </v-col>

          <v-col class="col-10 pt-0">
            <v-btn :loading="loading" :color="variables.colorSuccess" @click.prevent="openModal" class="br-btn  br-btn-filtro mr-2"> Novo </v-btn>
            <v-btn :loading="loading" :color="variables.colorPrimary" class="br-btn br-btn-filtro" @click="eventBuscar">Buscar</v-btn>
          </v-col>

          <v-col class="col-2 pt-0">
            <v-btn :loading="loading" :color="variables.colorPrimary" :class="[historico === false ? 'br-btn-activate' : 'br-btn-activate-active']" @click="getHistorico" style="height: 36px !important">Visualizar Histórico</v-btn>
          </v-col>
      </v-row>
    </v-card>
 </div>
</template>

<script>
import Events from '@/core/service/events'
import { mapActions, mapGetters } from 'vuex'
import modalCadastro from '@/views/cadastros/entities/mixins/modalCadastro'
import moment from 'moment'
import { mask } from 'vue-the-mask'
export default {
  name: 'FiltroPreCaixa',
  props:['historico', 'loading'],
  mixins: [modalCadastro],
  directives: {mask},
  components:{
    // dateTimerPicker: () => import('@/views/components/dateTimePicker'),
    autoComplete: () => import('./autoCompleteEntities')
  },
  data: () => ({
    datetime: new Date(),
    dtInicial: '',
    dtFinal: '',
    dtInicialString: moment().format('DD/MM/YYYY'),
    dtFinalString: moment().format('DD/MM/YYYY'),
    format: 'YYYY-MM-DD',
    defaultEntities: ['4','5','7','8'],
    menuDateInicial: false,
     menuDateFinal: false
  }),
  computed: {
    ...mapGetters('preCaixa', ['filtro'])
  },
  mounted () {
    this.dtInicial = moment().toISOString().substr(0, 10)
    this.dtFinal = new Date().toISOString().substr(0, 10)
  },
  watch: {
    dtInicial (v) {
      this.filtro.dtStart = v
    },
    dtFinal (v) {
      this.filtro.dtEnd = v
    }
  },
  methods: {
    ...mapActions('preCaixa', ['getEstabelecimentos', 'filterEntities']),
    getHistorico () {
      console.log('asdsd')
      this.$emit('buscar', true)
    },
    eventBuscar () {
        this.$emit('buscar')
     },
     openModal () {
        Events.$emit('modalCadastro::precaixa', true)
     },
     testeDate (dt, dtString) {
      const testeDate =  moment(dtString, 'DD/MM/YYYY', true)
      if (testeDate.isValid()) {
        switch (dt) {
          case 'inicial':
            this.dtInicial = moment(dtString, 'DD/MM/YYYY').format('YYYY-MM-DD')
            return
          case 'final':
            this.dtFinal = moment(dtString, 'DD/MM/YYYY').format('YYYY-MM-DD')
            return
        }
      }
    },
    closeModalDatePicker (dt, date) {
      switch (dt) {
        case 'inicial':
          this.dtInicialString = moment(date, 'YYYY-MM-DD').format('DD/MM/YYYY')
          this.menuDateInicial = false
          return
        case 'final':
          this.dtFinalString = moment(date, 'YYYY-MM-DD').format('DD/MM/YYYY')
          this.menuDateFinal = false
          return
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import '~@/assets/styles/components/modal-cadastro';
.datas-input{
  margin-top: 28px
}
</style>
